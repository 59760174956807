import email from "../assets/email.svg";
import mobile from "../assets/mobile.svg";
import api from "../assets/api.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import git from "../assets/git.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.webp";
import react from "../assets/react.png";
import sass from "../assets/sass.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";



import frontend_img from "../assets/frontend_img.jpg";
import responsiv from "../assets/responsiv.jpg";
import ui_ux from "../assets/ui_ux.webp";
import web_developer from "../assets/web_developer.jpg";

export default {
  email,
  mobile,
  api,
  css,
  figma,
  git,
  html,
  javascript,
  react,
  sass,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  frontend_img,
  responsiv,
  ui_ux,
  web_developer,
};
