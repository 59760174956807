import React from "react";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://dk.linkedin.com/in/frederik-meiner-5a7884208"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillLinkedin />
        </a>
      </div>
      <div>
        <a
          href="https://github.com/frederikmeiner"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillGithub />
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/frederik.meiner"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
      </div>
    </div>
  );
};
export default SocialMedia;
